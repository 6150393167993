<template>
    <article >
        <div class="eventContainer">

            <div style="width: 100vw;height: 40px;">
                <el-button style="float: right;margin-top: 20px;margin-right: 5%;" type="primary" @click="add()" size="medium">New Announcement</el-button>

            </div>

            <div style="clear: both;"></div>
          

            <div class="mainView">
                <el-tabs v-model="activeName" style="font-size: 25px;">
                    <el-tab-pane label="Updates" name="first">


                        <el-table height="260" :data="updateArr"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
                            <el-table-column label="No." width="50" align="center">
                                <template slot-scope="scope">
                                    {{   scope.$index + 1}}
                                </template>
                            </el-table-column>
                            <el-table-column label="News, Updates, and Announcements" align="left">
                            
                                <template slot-scope="scope">
                                    {{  scope.row.title }}
                                </template>
                            </el-table-column>      	


                            <el-table-column width="200" label="Project" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.project_code }}
                                </template>
                            </el-table-column>

                            <el-table-column width="200" label="Published" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.date_str }}
                                </template>
                            </el-table-column>

                            <el-table-column width="200" label="Operations" align="center">
                                <template slot-scope="scope">
                                    <el-button class="btn-link" @click="toCheck(scope.row)" type="primary" size="small">Check</el-button>
                                    <el-button class="btn-link" @click="deleteBulletin(scope.row)" type="primary" size="small">Delete</el-button>

                                </template>
                            </el-table-column>
                        </el-table>
                  

                    </el-tab-pane>
                    <el-tab-pane label="Pending Release" name="second">
                    

                        <el-table height="260" :data="releaseArr"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
                            <el-table-column label="No." width="50" align="center">
                                <template slot-scope="scope">
                                    {{  scope.$index + 1}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Content" align="left">
                            
                                <template slot-scope="scope">
                                    {{  scope.row.title }}
                                </template>
                            </el-table-column>      
                            
                            <el-table-column width="200" label="Project" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.project_code }}
                                </template>
                            </el-table-column>

                            <el-table-column width="200" label="Estimated Release Time" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.date_str }}
                                </template>
                            </el-table-column>

                            <el-table-column width="200" label="Operations" align="center">
                                <template slot-scope="scope">
                                    <el-button class="btn-link" @click="toCheck(scope.row)" type="primary" size="small">Check</el-button>
                                    <el-button class="btn-link" @click="deleteBulletin(scope.row)" type="primary" size="small">Delete</el-button>

                                </template>
                            </el-table-column>
                        </el-table>

                    </el-tab-pane>


                    <el-tab-pane label="Policies" name="third">

                        <el-table height="260" :data="policyArr"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
                            <el-table-column label="No." width="50" align="center">
                                <template slot-scope="scope">
                                    {{  scope.$index + 1 }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Policy" align="left">
                            
                                <template slot-scope="scope">
                                    {{  scope.row.title }}
                                </template>
                            </el-table-column>      	

                            <el-table-column width="200" label="Project" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.project_code }}
                                </template>
                            </el-table-column>

                            <el-table-column width="200" label="Last Updated" align="center">
                                <template slot-scope="scope">
                                  {{ scope.row.date_str }}
                                </template>
                            </el-table-column>
                            	
                            <el-table-column width="200" label="Operations" align="center">
                                <template slot-scope="scope">
                                    <el-button class="btn-link" @click="toCheck(scope.row)" type="primary" size="small">Check</el-button>
                                    <el-button class="btn-link" @click="deleteBulletin(scope.row)" type="primary" size="small">Delete</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                    </el-tab-pane>
                </el-tabs>
            </div>

        
            
        </div>


        <el-dialog :visible.sync="showDetail" title="Announcement Detail">

            <div>
                <el-form :model="detailInfo" ref="form2">
                    
                    <el-form-item label="project">
                        <el-input v-model="detailInfo.project_code"></el-input>
                    </el-form-item>

                    <el-form-item label="date">
                        <el-date-picker v-model="detailInfo.date" type="date"
                            value-format="timestamp" placeholder="please select">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="title">
                        <el-input v-model="detailInfo.title"></el-input>
                    </el-form-item>

                    <el-form-item label="type" prop="project_status">
                        <el-select v-model="detailInfo.type">
                            <el-option label="update" value="update"></el-option>
                            <el-option label="pending release" value="pending release"></el-option>
                            <el-option label="policy" value="policy"></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <div>Content</div> -->

                    <el-form-item label="">
                        <p>Content</p>

                        <quill-editor class="editor" style="margin-top:10px;margin-bottom:20px"  v-model="detailInfo.content"
                            :options="editorOption" ref="myTextEditor">

                        </quill-editor>
                    </el-form-item>
                 
                </el-form>


                <el-button type="primary" size="small" @click="save" style="margin-top: 20px">保存</el-button>

            </div>

        </el-dialog>

    </article>
</template>
<script>
import {
    getBulletin,
    updateBulletin,
    deleteBulletin
} from "../../api/index";

export default {
    data() {
    return {

        activeName:'first',
        
        updateArr:[],
        releaseArr:[],
        policyArr:[],
        showOngoing:false,
        showDetail:false,
        detailInfo:{
            id:'',
            project_code:'',
            date:'',
            type:'',
            title:'',
            content:' '
        },

        editorOption: {
            modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块
                [{header: 1}, {header: 2}], // 1、2 级标题
                [{list: 'ordered'}, {list: 'bullet'}], // 有序、无序列表
                [{script: 'sub'}, {script: 'super'}], // 上标/下标
                [{indent: '-1'}, {indent: '+1'}], // 缩进
                // [{'direction': 'rtl'}],                         // 文本方向
                [{size: ['small', false, 'large', 'huge']}], // 字体大小
                [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题
                [{color: []}, {background: []}], // 字体颜色、字体背景颜色
                [{font: []}], // 字体种类
                [{align: []}], // 对齐方式
                ['clean'], // 清除文本格式
                ['link', 'image', 'video'] // 链接、图片、视频
            ] // 工具菜单栏配置
            },
            placeholder: '', // 提示
            readyOnly: false, // 是否只读
            theme: 'snow', // 主题 snow/bubble
            syntax: true // 语法检测
        }
        

    };
    },
    created() {
    

    },
    mounted() {

        this.getBulletin()

    },
    methods: {

        changeQuill(){

        },
        add(){
            this.showDetail = true
            this.detailInfo.id = ''
            this.detailInfo.project_code = ''
            this.detailInfo.date = ''
            this.detailInfo.type = ''
            this.detailInfo.title = ''
            this.detailInfo.content = ' '
        },


        toCheck(row){

            this.showDetail = true
            this.detailInfo.id = row._id.$id
            this.detailInfo.project_code = row.project_code
            this.detailInfo.date = row.date
            this.detailInfo.type = row.type
            this.detailInfo.title = row.title
            this.detailInfo.content = row.content

        },

    
        save(){

            // export function (project_code,date,title,type,content) {

            updateBulletin(this.detailInfo.id,this.detailInfo.project_code,this.detailInfo.date,
                this.detailInfo.title,this.detailInfo.type,this.detailInfo.content
            ).then((res) => {

                this.showDetail = false

                this.$message.success('Add Successfully');

                this.getBulletin()

            });

        },

        getBulletin(){



            getBulletin('admin').then((res) => {

                if (res.data.code == 0) {

                  
                    this.updateArr = res.data.data.update
                    this.releaseArr = res.data.data.release
                    this.policyArr = res.data.data.policy



                } else {
                    this.$message.error(res.msg);
                }

            });





            
        },


    

        deleteBulletin(item){



            var info = 'Are you sure you want to delete'

            this.$confirm(info, 'Delete', {
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Close',
                    type: 'warning'
                    }).then(() => {
                        
                        deleteBulletin(item._id.$id).then(res=>{

                            this.$message({
                                message:'Delete Successfully.',
                                type:'success'
                            })
                            this.getBulletin()

                        })
                    })

        },



    },
};
</script>
<style>
    .ql-container{
        height: 180px!important;
    }
</style>
<style lang="scss">


article {

    background-color: rebeccapurple;
    // margin-left: 100px;
    // margin-top: 50px;
    background: #fff;
    // padding: 0 10px;
    background: yellow;
    .el-tabs_item.is-active{
        font-size: 14px;
        font-weight: 500;
        color: #204386;
    }
    .el-tabs_active-bar{
        background-color: #ffb414;
    }
    .el-tabs_item{
        font-size: 20px;
        font-weight: 500;
        color: aquamarine;
    }

    
    .eventContainer {
        height: calc(100vh - 80px);
        width: 100vw;
        // display: flex;
        overflow: hidden;
        background: #fff;
        // background: #ffb414;
        font-family: "DDINAlternateRegular";
        
        .mainView {
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 10px;
            flex: 1;
            // width: 100%;
            height: 90%;
            background: white;
            // overflow: hidden;
            position: relative;
        }
    }


}
</style>